import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Image from 'common/components/Image';
import PropTypes from 'prop-types';
import {Icon} from 'react-icons-kit';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';
import {
  TestimonialWrapper,
  TestimonialItem,
  TestimonialHead,
  TestimonialThumb,
  PrevButton,
  NextButton
} from './testimonial.style';
import {twitter} from 'react-icons-kit/icomoon/twitter';

const TestimonialSection = ({
                              sectionWrapper,
                              secTitleWrapper,
                              secTitle,
                              secDescription,
                              reviewStyle,
                              nameStyle,
                              designationStyle,
                            }) => {

  const testimonials = [
    {
      image: 'https://storage.googleapis.com/static.easypickup.de/images/118-logo.png?v=1611679035.095402',
      review: 'Mit easyresto sind wir endlich ein Stück unabhängiger von den großen Plattformen geworden. Wir' +
        ' fühlen uns gut aufgehoben und unsere Kunden auch.',
      name: 'Tullio Marotta',
      restaurant: 'Pizz&Via',
      designation: 'Zur Webseite',
      restaurantUrl: 'https://delivery.pizzevia.de/#/'
    },
    {
      image: 'https://storage.googleapis.com/static.easypickup.de/images/111-logo.png?v=1606747893.217734',
      review: 'Easyresto ist das perfekte Online-Bestellsystem als Aushängeschild im Web.\n' +
        '                                            Für unsere Kunden ist es einfach und intuitiv zu bedienen.\n' +
        '                                            Wir sind vollkommen zufrieden.',
      name: 'Raphael Jonas Holczer',
      restaurant: 'Lausbub',
      designation: 'Zur Webseite',
      restaurantUrl: 'https://order.lausbub-pizza.de/#/'
    },
    {
      image: 'https://storage.googleapis.com/static.easypickup.de/images/51-logo.png',
      review: 'Viele unserer Kunden schwärmen davon, wie einfach es ist,\n' +
        '                                            über unsere Webseite zu bestellen. Durch easyresto sparen\n' +
        '                                            wir jeden Monat viele Gebühren die wir sonst an die\n' +
        '                                            Plattformen zahlen würden.',
      name: 'Amar Mansouri',
      restaurant: 'Mam Mam Burger',
      designation: 'Zur Webseite',
      restaurantUrl: 'https://nuernberg.mam-mam-burger.de/#/'
    },
    {
      image: 'https://storage.googleapis.com/static.easypickup.de/images/116-logo.png',
      review: 'Wir sind begeistert wie einfach und schnell die\n' +
        '                                            Einrichtung funktioniert hat. Nach zwei Tagen waren wir online.\n' +
        '                                            Unsere Kunden sind begeistert wie einfach der Bestellvorgang ist.',
      name: 'Nga Nguyen',
      restaurant: 'Kokoro',
      designation: 'Zur Webseite',
      restaurantUrl: 'https://delivery.kokororestaurant.de/#/'
    },
  ];

  //Carousel Options
  const carouselOptions = {
    type: 'carousel',
    autoplay: 6000,
    perView: 3,
    gap: 28,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 3,
      },
      767: {
        perView: 2,
      },
      500: {
        perView: 1,
      },
    },
  };

  return (
    <Box {...sectionWrapper} as="section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Kundenbewertung"/>
        </Box>
        <TestimonialWrapper>
          <GlideCarousel
            carouselSelector="testimonial-carousel"
            options={carouselOptions}
            prevButton={
              <PrevButton>
                <span/>
              </PrevButton>
            }
            nextButton={
              <NextButton>
                <span/>
              </NextButton>
            }
          >
            <>
              {testimonials.map((item, index) => (
                <GlideSlide key={`testimonial-item-${index}`}>
                  <TestimonialItem>
                    <TestimonialHead>
                      <TestimonialThumb>
                        <Image
                          src={item.image}
                          alt={`testimonial-avatar-${index + 1}`}
                        />
                      </TestimonialThumb>
                    </TestimonialHead>
                    <Text {...reviewStyle} content={item.review}/>
                    <Heading as="h3" content={item.name} {...nameStyle} />
                    <Text
                      as="span"
                      content={item.restaurant}
                      {...designationStyle}
                    />
                    <a
                      href={item.restaurantUrl || '#'}
                      className="reviewer_org"
                    >
                      {item.designation}
                    </a>
                  </TestimonialItem>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </TestimonialWrapper>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  reviewStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '150px'],
    pb: '50px',
  },
  secTitleWrapper: {
    mb: ['90px', '90px', '50px', '50px', '50px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    width: '530px',
    maxWidth: '100%',
    mb: '0',
  },
  reviewStyle: {
    fontSize: '16px',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '30px',
  },
  nameStyle: {
    fontSize: '16px',
    color: '#302b4e',
    fontWeight: '600',
    mb: '7px',
  },
  designationStyle: {
    fontSize: '14px',
    color: '#43414e',
    mb: '0',
  },
};

export default TestimonialSection;
