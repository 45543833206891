import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 72px 0;
  @media only screen and (max-width: 1440px) {
    margin: 72px 0;
  }
  @media only screen and (max-width: 1366px) {
    margin: 72px 0;
  }
  @media only screen and (max-width: 1024px) {
   margin: 64px 0;
  }
  @media only screen and (max-width: 480px) {
    margin: 48px 0;
  }
  @media only screen and (max-width: 360px) {
    margin: 36px 0;
  }
`;

export const TextContent = styled.div`
  max-width: 550px;
  @media only screen and (max-width: 1200px) {
    max-width: 377px;
  }
  @media only screen and (max-width: 768px) {
    margin: 20px auto 0;
    text-align: center;
  }
  h2 {
    color: #0e212f;
    font-weight: 700;
    font-size: 62px;
    line-height: 1.13;
    letter-spacing: -2px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1440px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 1366px) {
      font-size: 45px;
      line-height: 1.3;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 34px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 28px;
    }
  }
  p {
    color: #0e212f;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.9;
    letter-spacing: -0.3px;
    max-width: 514px;
    margin-bottom: 45px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.33;
    color: #009edf;
    margin-left: 16px;
    margin-top: 16px;
  }
`;

export const Illustration = styled.figure`
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  @media only screen and (max-width: 768px) {
    display: none;
  }
  img {
    max-width: 93%;
    margin-left: auto;
    @media only screen and (max-width: 1600px) {
      max-width: 75%;
      height: auto;
      margin-top: 190px;
    }
    @media only screen and (max-width: 1440px) {
      max-width: 73%;
    }
    @media only screen and (max-width: 1366px) {
      max-width: 62%;
      margin-top: 250px;
    }
    @media only screen and (max-width: 1200px) {
      max-width: 60%;
      margin-top: 300px;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 54%;
      margin-top: 330px;
    }
  }
`;

export const MobileIllustration = styled(Illustration)`
  @media only screen and (min-width: 768px) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: inline-flex;
    width: 100%;
    align-items: center;
    right: 0;
    top: 0;
    position: relative;
    height: auto;
    transform: none;
  }
  img {
    margin-top: 0;
    height: auto;
    max-width: 100%;
  }
`;
