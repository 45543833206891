import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import {Button} from 'baseui/button';
import Text from 'common/components/Text';
import GatsbyImage from 'common/components/GatsbyImage';
import {
  Section,
  ContentWrapper,
  TextContent,
  Illustration,
  MobileIllustration,
  ButtonGroup,
} from './banner.style';
import {Link} from 'gatsby';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/saasMinimal2/banner/marketing-hero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 550
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <Section id="home" style={{minHeight: 'calc(100vh - 250px)'}}>
      <Container>
        <ContentWrapper>
          <TextContent>
            <Heading content="Intelligentes Marketing für die Gastronomie"/>
            <MobileIllustration>
              <GatsbyImage
                src={
                  (data.illustration !== null) | undefined
                    ? data.illustration.childImageSharp.gatsbyImageData
                    : {}
                }
                alt="Illustration"
              />
            </MobileIllustration>
            <Text
              content="Erreiche mehr Gäste mit KI-gestützten Strategien. Automatisiere dein Marketing, binde Stammgäste und steigere deinen Umsatz – alles ohne zusätzlichen Aufwand. Lass die KI für dein Restaurant arbeiten."/>
            <ButtonGroup>
              <Link to="/contact-us/?web=1&app=1">
                <Button kind="primary">Mit Easyresto starten</Button>
              </Link>
            </ButtonGroup>
          </TextContent>
        </ContentWrapper>
      </Container>
      <Illustration>
        <GatsbyImage
          src={
            (data.illustration !== null) | undefined
              ? data.illustration.childImageSharp.gatsbyImageData
              : {}
          }
          alt="Illustration"
        />
      </Illustration>
    </Section>
  );
};

export default Banner;
